
import CloudFun, { Condition, Operator } from '@cloudfun/core'
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: Boolean,
    // productIds: Array as PropType<number[]>,
  },
  emits: ["update:modelValue", "submit"],
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const query = reactive<{
      brand: number[],
      category: number[],
      country: number[],
      keyword: string
    }>({
      brand: [],
      category: [],
      country: [],
      keyword: ""
    });
    const selectedProducts = ref<any[]>([]);
    const brands = ref<any[]>([]);
    const categories = ref<any[]>([]);
    const countries = ref<any[]>([]);
    const products = ref<any[]>([]);

    const selectedProductIds = computed(() => selectedProducts.value.map(e => e.Id));

    // watch(() => props.productIds, (cur, pre) => {
    //   if (!cur || cur === pre) { return; }
    //   selectedProducts.value = cur.map(id => ({ Id: id }));
    // }, { immediate: true })

    const loadProducts = async () => {
      const params = { keyword: query.keyword } as any;
      params.condition = new Condition();
      if (query.brand.length > 0) params.condition.and("BrandId", Operator.In, query.brand);
      if (query.category.length > 0) params.condition.and("CategoryId", Operator.In, query.category);
      if (query.country.length > 0) params.condition.and("Brand.CountryId", Operator.In, query.country);
      products.value = await model?.dispatch("product/query", params);
    }

    onMounted(async () => {
      categories.value = await model?.dispatch("category/query");
      countries.value = await model?.dispatch("country/query");
      brands.value = await getBrands();
      loadProducts();
    });
    const getBrands = () => {
      return model?.dispatch("brand/query").then((data) => {
        data = data.filter((item: any) => {
          return item.Name !== null
        }).sort((a: any, b: any) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        return data;
      }).catch((error) => {
        console.log(error)
      })
    }

    const hideModal = () => {
      emit("update:modelValue", false)
    }

    const onQuery = (type: "brand" | "category" | "country" | "keyword", id: number | string) => {
      if (type === "keyword") {
        query[type] = id as string;
      } else {
        const value = id as number;
        const idx = query[type].indexOf(value);
        if (idx > -1) query[type].splice(idx, 1);
        else query[type].push(value);
      }
      loadProducts();
    }

    const onSelect = (row: any) => {
      const idx = selectedProductIds.value.indexOf(row.Id);
      if (idx > -1) selectedProducts.value.splice(idx, 1);
      else selectedProducts.value.push(row);
    }
    const selectAll = () => {
      console.log('selectAll');
      if (selectedProducts.value.length === products.value.length) {
        selectedProducts.value = [];
      } else {
        selectedProducts.value = [...products.value]
      }
    }

    const onSubmit = () => {
      emit("submit", selectedProducts.value)
      hideModal();
    }

    return {
      query,
      selectedProductIds,
      brands,
      categories,
      countries,
      products,
      hideModal,
      onQuery,
      onSelect,
      onSubmit,
      selectAll,
    }
  }
})
