<template>
  <VxeModal
    :modelValue="modelValue"
    title="選擇商品"
    width="90%"
    height="90%"
    show-footer
    @hide="hideModal"
  >
    <div class="grid grid-cols-4">
      <div>
        <div class="text-xl mb-3">關鍵字</div>
        <VxeInput
          class="w-full mb-3"
          @change="({ value }) => onQuery('keyword', value)"
        />
        <hr class="mb-3" />
        <div class="text-xl mb-3">品牌</div>
        <div
          class="border-2 rounded-2xl p-3 mb-3"
          :class="{ 'bg-blue-100': query.brand.includes(item.Id) }"
          v-for="item in brands"
          :key="'brand-filter-category-' + item.Id"
          @click="onQuery('brand', item.Id)"
        >
          {{ item.Name }}
        </div>
        <hr class="mb-3" />
        <div class="text-xl mb-3">商品類別</div>
        <div
          class="border-2 rounded-2xl p-3 mb-3"
          :class="{ 'bg-blue-100': query.category.includes(item.Id) }"
          v-for="item in categories"
          :key="'product-filter-category-' + item.Id"
          @click="onQuery('category', item.Id)"
        >
          {{ item.Name }}
        </div>
        <hr class="mb-3" />
        <div class="text-xl mb-3">品牌國家</div>
        <div
          class="border-2 rounded-2xl p-3 mb-3"
          :class="{ 'bg-blue-100': query.country.includes(item.Id) }"
          v-for="item in countries"
          :key="'product-filter-country-' + item.Id"
          @click="onQuery('country', item.Id)"
        >
          {{ item.Name }}
        </div>
      </div>
      <div class="col-span-3">
        <div class="grid grid-cols-3">
          <div
            v-for="item in products"
            :key="'product-select-' + item.Id"
            class="border-2 flex flex-col items-center justify-center m-3 rounded-2xl py-3"
            :class="{ 'bg-blue-100': selectedProductIds.includes(item.Id) }"
            @click="onSelect(item)"
          >
            <img
              width="200"
              :src="item.Photo?.Uri"
              loading="lazy"
            />
            123123
            <div class="grid grid-cols-12">
              <div class="col-span-6">品牌: {{ item.Brand?.Name }}</div>
              <div class="col-span-6">類別: {{ item.Category?.Name }}</div>
              <div class="col-span-6">編號: {{ item.Number }}</div>
              <div class="col-span-6">條碼: {{ item.Barcode }}</div>
              <div class="col-span-12">片數: {{ item.ProductAttributeValues?.find(e => e.AttributeValue?.Attribute?.Code ===
                "Pieces")?.AttributeValue?.DecimalValue }}</div>
              <div class="col-span-12">圖名: {{ item.Name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <vxe-button
        status="primay"
        content="全部選擇"
        @click="selectAll"
      ></vxe-button>
      <vxe-button
        status="primay"
        content="確認"
        @click="onSubmit"
      ></vxe-button>
      <vxe-button
        content="關閉"
        @click="hideModal"
      ></vxe-button>
    </template>
  </VxeModal>
</template>

<script lang="ts">
import CloudFun, { Condition, Operator } from '@cloudfun/core'
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: Boolean,
    // productIds: Array as PropType<number[]>,
  },
  emits: ["update:modelValue", "submit"],
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const query = reactive<{
      brand: number[],
      category: number[],
      country: number[],
      keyword: string
    }>({
      brand: [],
      category: [],
      country: [],
      keyword: ""
    });
    const selectedProducts = ref<any[]>([]);
    const brands = ref<any[]>([]);
    const categories = ref<any[]>([]);
    const countries = ref<any[]>([]);
    const products = ref<any[]>([]);

    const selectedProductIds = computed(() => selectedProducts.value.map(e => e.Id));

    // watch(() => props.productIds, (cur, pre) => {
    //   if (!cur || cur === pre) { return; }
    //   selectedProducts.value = cur.map(id => ({ Id: id }));
    // }, { immediate: true })

    const loadProducts = async () => {
      const params = { keyword: query.keyword } as any;
      params.condition = new Condition();
      if (query.brand.length > 0) params.condition.and("BrandId", Operator.In, query.brand);
      if (query.category.length > 0) params.condition.and("CategoryId", Operator.In, query.category);
      if (query.country.length > 0) params.condition.and("Brand.CountryId", Operator.In, query.country);
      products.value = await model?.dispatch("product/query", params);
    }

    onMounted(async () => {
      categories.value = await model?.dispatch("category/query");
      countries.value = await model?.dispatch("country/query");
      brands.value = await getBrands();
      loadProducts();
    });
    const getBrands = () => {
      return model?.dispatch("brand/query").then((data) => {
        data = data.filter((item: any) => {
          return item.Name !== null
        }).sort((a: any, b: any) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        return data;
      }).catch((error) => {
        console.log(error)
      })
    }

    const hideModal = () => {
      emit("update:modelValue", false)
    }

    const onQuery = (type: "brand" | "category" | "country" | "keyword", id: number | string) => {
      if (type === "keyword") {
        query[type] = id as string;
      } else {
        const value = id as number;
        const idx = query[type].indexOf(value);
        if (idx > -1) query[type].splice(idx, 1);
        else query[type].push(value);
      }
      loadProducts();
    }

    const onSelect = (row: any) => {
      const idx = selectedProductIds.value.indexOf(row.Id);
      if (idx > -1) selectedProducts.value.splice(idx, 1);
      else selectedProducts.value.push(row);
    }
    const selectAll = () => {
      console.log('selectAll');
      if (selectedProducts.value.length === products.value.length) {
        selectedProducts.value = [];
      } else {
        selectedProducts.value = [...products.value]
      }
    }

    const onSubmit = () => {
      emit("submit", selectedProducts.value)
      hideModal();
    }

    return {
      query,
      selectedProductIds,
      brands,
      categories,
      countries,
      products,
      hideModal,
      onQuery,
      onSelect,
      onSubmit,
      selectAll,
    }
  }
})
</script>
