
import { defineComponent, ref, watch, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VxeFormProps } from "vxe-table";
import ProductSelectModal from "@/components/product-select/Main.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    ProductSelectModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const selectedActivity = ref(0);
    const selectedBrand = ref(0);

    const gridOptions: GridOptions = {
      title: "活動產品",
      canCreate: true,
      canUpdate: false,
      canRead: false,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      // editConfig: {
      //   trigger: 'click',
      //   mode: 'cell',
      //   activeMethod({ columnIndex }) {
      //     return columnIndex === 2;
      //   }
      // },
      columns: [
        { field: "Product.Brand.Name", title: "品牌", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "130" },
        { field: "Activity.Name", title: "活動", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Product.Name", title: "產品", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Product.Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "100", fixed: "left" },
        { field: "Product.Barcode", title: "條碼", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "130", fixed: "left" },
        {
          field: "Product.Undiscountable", title: "不允許折扣", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
        {
          field: "IsRed", title: "是否為紅標", showHeaderOverflow: true, showOverflow: true, align: "center", sortable: true, resizable: false, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' }, events: { change({ row }: any) { model!.dispatch("activityProduct/update", row) } } }
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (selectedActivity.value) params.condition!.and("Activity.Id", Operator.Equal, selectedActivity.value);
            if (selectedBrand.value) params.condition!.and("Product.Brand.Id", Operator.Equal, selectedBrand.value);
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "CreatedTime", order: 1 });
            return model.dispatch('activityProduct/query', params)
          }
          : undefined,
        save: model
          ? (params) => model.dispatch('activityProduct/save', params)
          : undefined
      },
      modalConfig: { width: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'ActivityId', title: '活動', span: 24, slots: { default: "column-activity-id" } },
        // { field: 'BrandId', title: '品牌', span: 24, slots: { default: "column-brand-id" } },
        { field: 'ProductIds', title: '產品', span: 24, slots: { default: "column-product-ids" } },
        { field: "IsRed", title: "是否為紅標", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
      ],
      rules: {
        ActivityId: [{ required: true }],
        ProductIds: [{ required: true }],
        IsRed: [{ required: true }],
      }
    }

    watch(selectedActivity, value => {
      grid.value.refresh();
    })

    watch(selectedBrand, value => {
      grid.value.refresh();
    })

    const activitySelectOptions: SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇活動',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("activity/find", value),
        query: (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "StartTime", order: 1 });
          return model!.dispatch("activity/query", params)
        },
      }
    }

    const brandSelectOptions : SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇品牌',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("brand/find", value),
        query: (params) => {
          if (!params.sortings) params.sortings = [];
          // params.sortings.push({ column: "StartTime", order: 1 });
          return model!.dispatch("brand/query", params)
        },
      }
    }

    const productModal = reactive({
      itemsStr: "",
      visible: false,
      show() {
        productModal.visible = true;
      },
      submit(items: any[]) {
        grid.value.editingRow.ProductIds = items.map(e => e.Id);
        productModal.itemsStr = items.map(e => e.Name).join(', ');
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      selectedActivity,
      selectedBrand,
      activitySelectOptions,
      brandSelectOptions,
      productModal
    };
  }
});
